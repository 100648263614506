@media screen and (max-width: 991px) {
    .mobile-managestudents {
        .search-container {
            display: flex;
            align-items: center;
            border: 1px solid #ced4da;
            border-radius: 50px;
            padding: 5px 15px;
            margin: 15px 0px 20px 0;
        }

        .search-input {
            flex: 1;
            border: none;
            outline: none;
            padding: 5px 0.6rem;
            border-radius: 0;
        }

        .search-icon {
            color: #6c757d;
            margin-left: 10px;
        }

        .search-input::placeholder {
            color: #6c757d;
        }


        .mobile-card {
            padding: 0.5rem 0.7em;
            box-shadow: 0 0 2px 2px #e2e1e2;
            margin-bottom: 15px;
        }

        .card-content {
            font-weight: bold;
        }

        .iconColor {
            color: #8267ed;
            font-size: 30px;
        }

        .header-container {
            background-color: #8267ed;
            position: sticky;
        }

        .create-icon {
            padding: 0;
            text-align: end;
            color: #fff;
        }

        .heading-div {
            padding: 4em 0 2em;
            text-align: center;
        }

        .page-heading {
            font-size: 1.3rem;
            font-weight: bold;
            margin-top: 0;
        }

        .create.MuiSvgIcon-root {
            color: #fff;
            background-color: transparent;
            font-size: 25px;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            outline: none;
            padding: 0;
            position: absolute;
            right: 10px;
            top: 25px;
            transform: translateY(-50%);
        }

        .mobile-screen {
            padding: 10px 20px 70px;
            flex: 1;
            overflow-y: scroll;
        }

        .footer-pad {
            position: fixed;
            bottom: 0;
            width: 100%;
        }
    }
}