@media screen and (max-width: 991px) {
    .morepage {
        display: flex;
        flex-direction: column;

        .bg-color {
            position: sticky;
            top: 0;
            width: 100%;
            background-color: #8267ed;
            color: #fff;
            display: flex;
            justify-content: center;
            z-index: 1;
        }

        .bg-color h5.page-heading {
            padding: 3rem 0;
            justify-content: center;
            font-size: 1.3rem;
            font-weight: bold;
            margin-top: 0;
            margin-bottom: 0;
        }

        .contentContainer {
            flex: 1;
            padding: 10px 20px;
            overflow-y: scroll;
            background-color: #fff;
        }

        .mobile-card {
            padding: 10px;
            box-shadow: 0 0 2px 2px #e2e1e2;
            margin-bottom: 15px;
            border-radius: 8px;
        }

        .card-content {
            font-weight: bold;
        }

        .iconColor {
            color: #8267ed;
            font-size: 30px;
        }

        .footer-pad {
            position: fixed;
            width: 100%;
            bottom: 0;
        }
    }
}