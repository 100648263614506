@media screen and (max-width:991px) {
    .therapistprofilepage {
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        .bg-color {
            position: sticky;
            top: 0;
            width: 100%;
            background-color: #8267ed;
            color: #fff;
            display: flex;
            flex-direction: column;
            z-index: 100;
            padding: 0;
        }

        .contentContainer {
            flex: 1;
            padding: 10px 20px 70px;
            background-color: #fff;
        }

        .footer-pad {
            position: fixed;
            bottom: 0;
            width: 100%;
        }

        .page-heading {
            font-size: 1.3rem;
            font-weight: bold;
            margin-top: 0;
            margin-bottom: 0;
            padding: 3rem 0;
        }

        .button-absolute {
            position: absolute;
            bottom: 67px;
            width: 100%;
        }
    }
}