.textDecoration {
    text-decoration: none;
}

.section-heading {
    font-size: 1.3rem;
    font-weight: bold;
}

.card.color-card1 {
    box-shadow: none;
    margin-top: 10px;
    padding: 15px;
    border: none;
    background-color: #ececec;
}

.card.color-card2 {
    box-shadow: none;
    margin-top: 10px;
    padding: 15px;
    border: none;
    background-color: #8267ed50;
}


@media screen and (max-width: 991px) {
    .mobile-dashboard {
        display: flex;
        flex-direction: column;

        .MuiSvgIcon-root.dashboard-icon{
            font-size: 1.2em;
            height: 1.2em;
            width: 1.2em;
        }
        svg.dashboard-icon{
            font-size: 1.2em;
            height: 1.2em;
            width: 1.2em;
        }

        h5.page-heading{
            margin-bottom: 0;
        }

        .card.common-card {
            margin-top: 0.4em;
            margin-bottom: 0.5em;
            padding: 0.5em 1em;
            box-shadow: 0 0 4px 4px #ededed;
        }

        .card.color-card1 {
            box-shadow: none;
            margin-top: 0.4em;
            padding: 1em;
            border: none;
            background-color: #ececec;
        }

        .card.color-card2 {
            box-shadow: none;
            margin-top: 0.4em;
            padding: 1em;
            border: none;
            background-color: #8267ed50;
        }

        .section-heading {
            font-size: 20px;
            font-weight: bold;
        }

        .justify-content-space-between {
            justify-content: space-between;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .mobile-icon.MuiSvgIcon-root {
            margin-right: 10px;
        }

        .createContainer {
            padding: 0;
        }

        .bg-color {
            position: sticky;
            top: 0;
            width: 100%;
            background-color: #8267ed;
            color: #fff;
            display: flex;
            z-index: 1;
        }

        .contentContainer {
            flex: 1;
            overflow-y: scroll;
            padding: 0px 20px 20px;
            background: #fff;
            min-height: 69vh;
        }

        .createContainer.admin-dashboard {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .bg-color.admin-dashboard {
            position: sticky;
            top: 0;
            width: 100%;
            background-color: #8267ed;
            color: #fff;
            display: flex;
            z-index: 1;
        }

        .bg-color.admin-dashboard h5.page-heading {
            padding: 3rem 1rem;
        }

        .curvy-div {
            background: linear-gradient(to bottom, #8267ed 90%, #fff 90%, #fff 100%);
            width: 100%;
        }

        .innercurvy-div {
            border-radius: 20px 20px 0 0;
            background-color: #fff;
            height: 30px;
            margin-bottom: -3px;

        }

        .contentContainer.admin-dashboard {
            flex: 1;
            overflow-y: scroll;
            padding: 2em 1rem 5em;
            background: #fff;
        }

        .footer-pad {
            position: fixed;
            bottom: 0;
            width: 100%;
            background-color: #fff;
        }

        .summary-names {
            font-weight: 600;
            font-size: 1rem;
        }

        .therapistInfo {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .therapistInfo .contentContainer, .studentInfo .contentContainer{
            padding: 2em 1rem 5em;
        }

        .therapist-name.page-heading {
           padding: 3rem 1rem;
        }

        .studentprofile {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .student-name.page-heading {
           padding: 3rem 1rem;
        }
    }
}