.audio-recorder-page {
    .violet-bg {
        background-color: #8267ed50;
        padding: 8px 15px;
        border-radius: 50px;
        align-items: center;
        cursor: pointer;
    }

    .iconContainer {
        border-radius: 50%;
        background-color: transparent;
        padding: 0 2px;
        border: 1px solid black;
    }

    .stopiconContainer {
        border: 1px solid black;
        border-radius: 50%;
        padding: 0 4px;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    audio::-webkit-media-controls-download-button {
        display: none;
    }

    /* Hide the playback speed button */
    audio::-webkit-media-controls-speed-menu-button {
        display: none;
    }

}