@media screen and (max-width: 991px) {
    .search-container {
        display: flex;
        align-items: center;
        border: 1px solid #ced4da;
        border-radius: 50px;
        padding: 5px 15px;
        margin: 15px 0px 20px 0;
    }

    .search-input {
        flex: 1;
        border: none;
        outline: none;
        padding: 5px 10px;
        border-radius: 50px;
    }

    .search-icon {
        color: #6c757d;
        margin-left: 10px;
    }

    .search-input::placeholder {
        color: #6c757d;
    }

    .mobile-card {
        padding: 10px;
        box-shadow: 0 0 2px 2px #e2e1e2;
        margin-bottom: 15px;
    }

    .iconColor.MuiSvgIcon-root {
        color: #8267ed;
        font-size: 30px;
    }

    .mobile-managetherapist {
        .header-container {
            background-color: #8267ed;
            padding: 35px 0;
            position: sticky;
            top: 0;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .contentContainer {
            flex: 1;
            padding: 10px 20px;
        }

        .icon-position {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            outline: none;
            padding: 0;
            position: absolute;
            left: 10px;
            top: 20%;
            transform: translateY(-50%);
        }

        .create {
            color: #fff;
            background-color: transparent;
            font-size: 25px;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            outline: none;
            padding: 0;
            position: absolute;
            right: 10px;
            top: 20%;
            transform: translateY(-50%);
        }

        .page-heading {
            font-size: 1.3rem;
            font-weight: bold;
            margin-top: 10px;
        }

        .card-content {
            font-weight: bold;
        }
    }
}