@media screen and (max-width:991px) {
    .mobile-managesessions {
        display: flex;
        flex-direction: column;

        .contentContainer {
            flex: 1;
            overflow-y: scroll;
            padding: 10px 20px 70px;
            background: #fff;
        }

        .header-container {
            background-color: #8267ed;
            padding: 4rem 0 2rem;
            position: sticky;
            top: 0;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .create {
            color: #fff;
            background-color: transparent;
            font-size: 25px;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            outline: none;
            padding: 0;
            position: absolute;
            right: 10px;
            top: 20%;
            transform: translateY(-50%);
        }

        .page-heading {
            font-size: 1.3rem;
            font-weight: bold;
            margin-top: 0;
            margin-bottom: 0;
        }

        .card-content {
            font-weight: bold;
        }

        .footer-pad {
            position: fixed;
            bottom: 0;
            width: 100%;
        }
    }
}