@media screen and (max-width: 991px) {
    .mobile-editStudent {
        display: flex;
        flex-direction: column;

        .bg-color {
            position: sticky;
            top: 0;
            width: 100%;
            background-color: #8267ed;
            color: #fff;
            display: flex;
            flex-direction: column;
            z-index: 100;
            padding: 20px 10px;
        }

        .contentContainer {
            flex: 1;
            overflow-y: scroll;
            padding: 10px 20px;
            background: #fff;
        }

        .page-heading {
            font-size: 1.3rem;
            font-weight: bold;
            margin-top: 10px;
        }

    }
}