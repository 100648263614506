.loginContainer {
  display: flex;
}

.pwd-screen {
  .loginLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;

    p:last-child {
      font-size: 1.2rem;
      font-weight: 500;
    }

    p {
      text-align: center;
      font-size: 1.2rem;
      /* font-weight: 500; */
    }

    p a {
      font-weight: 700;
    }
  }

  .loginRight {
    background: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    padding: 0 25%;
    /* color: white; */
  }

  .headingText {
    font-size: 1.5rem;
  }

  .image {
    max-height: 50vh;
    max-width: 80%;
    margin: 10px 0 15px;
  }

  .loginText {
    font-size: 1.5rem;
    justify-content: flex-start;
    margin: 0;
    margin-bottom: 30px;
    font-weight: 500;
  }

  .submitButton {
    color: white;
    background-color: black;
    border-radius: 50px;
    padding: 5px 25px;
    border: 1px solid #ffe600;
  }

  .submitButton:hover {
    color: black;
    border: 1px solid #000;
    background-color: white;
  }

  .forgot {
    font-size: 1.1rem;
  }

  .btn.btn-white,
  .btn.btn-white:focus {
    background: #fff;
    outline: none;
    border: none;
  }

  .btn.btn-white:hover {
    background: #ffffff95;
    outline: none;
    border: none;
  }

  .btn.btn-light,
  .btn.btn-light:focus {
    background: transparent;
    outline: none;
    border: none;
  }

  .btn.btn-light:hover {
    background: #ffffff30;
    outline: none;
    border: none;
  }

}


@media screen and (max-width:991px) {
  .pwd-screen .loginRight {
    padding: 0 8%;
  }

  .pwd-screen .btn.btn-light {
    padding-top: 2px;
  }

  .pwd-screen {
    .bg-color {
      background-color: #8267ed;
      color: #fff;
      display: flex;
      flex-direction: column;
      padding: 0;
      position: sticky;
    }

    .contentContainer {
      flex: 1;
      padding: 20px 20px;
    }

    .createContainer {
      padding: 0 !important;
    }

    .loginText{
      font-size: 20px;
      font-weight: 600;
    }
    .page-heading{
      padding: 3.5em 0;
    }
  }
}