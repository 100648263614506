.sidebarContainer {
    background-color: #fff;
    box-shadow: 0 0 1px 1px #e1d4d4;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 80px);
    overflow: auto;
    width: 300px;
    margin-right: 1px;
}

.sidebarContainer p {
    font-weight: 500;
    font-size: 1.1rem;
}

.sidebarContainer p a {
    font-weight: 700;
}

.mobile .sidebarContainer {
    position: fixed;
    left: 0;
    top: 80px;
    background-color: #fff;
    width: 300px;
    height: calc(100vh - 80px);
    overflow: auto;
    z-index: 10;
}

.logoImage {
    text-align: center;
    padding: 15px 18px;
    box-shadow: 0 0 2px 2px #e2e1e2;
}

.sidebarContent {
    padding: 30px 0px;
}

.subContent {
    display: flex;
    align-items: center;
    padding: 10px 0;
    gap: 0 15px;
}

.icon {
    font-size: 20px;
    cursor: pointer;
    margin-left: 30px;
    height: unset !important;
}

.actualText {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 400;
    cursor: pointer;
}

.subContent.active,
.subContent:hover {
    background-color: #8267ED;
    color: white;
}

.sidebar-link {
    color: black;
    text-decoration: none;
}

.mt-auto {
    margin-top: auto;
}