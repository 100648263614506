*,::after,::before{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
#root{
  min-height: calc(100vh - 60px);
  max-height: 100vh;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.overallPages{
  display: flex;
}
.components{
  display: flex;
  width: 100vw;
}
.mainContainer{
  flex: 1;
}
.mobileThemeColor{
  color: #8267ed;
  font-weight: 600;
  font-size: 18px;
}
/* common-styles */
.createContainer{
    padding: 30px;
    height: calc(100vh - 80px);
    background-color: #fcfbfb;
    overflow: auto;
}
.editContainer{
    padding: 30px;
    height: calc(100vh - 80px);
    background-color: #fcfbfb;
    overflow: auto;
}
.manageContainer{
    padding: 30px;
    height: calc(100vh - 80px);
    background-color: #fcfbfb;
    overflow: auto;
}
.page-heading{
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 25px;
}
.card.common-card{
  border: none;
  padding: 30px;
  margin-top: 30px;
}
.buttonsRow{
  display: flex;
  justify-content: center;
}
.createButton{
  background-color: #8267ED;
  border-radius: 5px;
  padding: 5px 25px;
  width: fit-content;
  color: #fff;
  cursor: pointer;
  border: 1px solid #8267ED;
  /* font-size: 1.1rem; */
  text-transform: uppercase;
}
.createButton:hover{
  background-color: white;
  color: #8267ED;
  border: 1px solid #8267ED;
  text-transform: uppercase;
}
.cancelButton{
  background-color: white;
  color: #8267ED;
  border: 1px solid #8267ED;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 5px 25px;
  width: fit-content;
  cursor: pointer;
}
.cancelButton:hover{
  background-color: #8267ED;
  color: white;
  border: 1px solid #8267ed;
  text-transform: uppercase;
}
.searchbar-div{
  justify-content: space-between;
  display: flex;
  align-items: end;
}
.searchbar{
  justify-content: flex-start;
}
.buttonCreate{
  justify-content: flex-end;
  text-transform: uppercase;
}
.create{
  background-color: #8267ED;
  color: white;
  padding: 3px 10px;
  border-radius: 5px;
  text-transform: uppercase;
  border: 1px solid #8267ED;
}
.card.manage-card{
  border-radius: 0;
  padding: 20px;
  border: none;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, .2), 0px 8px 10px 1px rgba(0, 0, 0, .14), 0px 3px 14px 2px rgba(0, 0, 0, .12);
}
.common-table th,
.common-table td{
    width: unset;
    min-width: 200px;
}
.col-width{
  width: 300px;
  text-align: center;
}
.loader-swal{
  background: transparent;
}
.summary-names{
  font-weight: 500;
  font-size: 1.2rem;
}
.tab-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 8px 16px;
  font-size: 1em;
  font-weight: bold;
  color: #333;
  transition: all 0.3s ease;
  gap: 30px;
  text-transform: uppercase;

}

/* Styles for the active tab button */
.tab-button.active {
  color: #8267ED;
  border-bottom: 2px solid #8267ED;
  text-transform: uppercase;
}

/* Styles for the tab container */
.tab-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.cust-violet-input-field .MuiFormLabel-root.MuiInputLabel-root{
  color: #000;
}
.cust-violet-input-field .MuiInputBase-input.MuiOutlinedInput-input{
  color: #000;
}
.cust-violet-input-field .MuiOutlinedInput-notchedOutline{
  border-color: #000;
}
.cust-violet-input-field .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #8267ed;
}
.cust-violet-input-field .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
  border-color: #8267ed;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked{
  color: #8267ED;
}
.MuiButtonBase-root.MuiCheckbox-root.Mui-disabled{
  color: #8267ED;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  background-color: #8267ED;
  color: white;
}


.fw-500{
  font-weight: 600;
}
.mobile .mainContainer{
    flex: 1;
    max-width: 100%;
}
.mobile .manage-card{
    overflow: auto;
}
.mobile .tableContainer{
    min-width: 1200px;
    display: flex;
    flex-direction: column;
}
.mobile .page-heading {
    font-size: 1.3rem;
    font-weight: 600;
}
.home-protocols{
  font-size: 1.1em;
  font-weight: 600;
  margin-top: .6em;
}
@media screen and (max-width: 991px) {
    .createContainer{
        padding: 20px 15px;
        background-color: #fff;
        height: calc(100vh - 70px);
    }
    .editContainer{
        padding: 20px 15px;
        background-color: #fff;
        height: calc(100vh - 70px);
    }
    .manageContainer{
        padding: 20px 15px;
        background-color: #fff;
        height: calc(100vh - 70px);
    }
    .card.common-card {
      padding: 20px;
      margin-top: 30px;
    }
    .flex-1{
        flex: 1;
    }
    .mobile-footer{
        box-shadow: 0 0 3px 3px #e2e1e2;
    }
    
    .mobileComponents{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .mainContainer{
      flex: 1;
      width: 100%;
    }
}