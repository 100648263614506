.viewpaymentsContainer{
    padding: 30px;
    height: calc(100vh - 80px);
    background-color: #e2e1e2;
    overflow: auto;
}
.payments-page.buttonCreate{
    align-self: end;
}
@media screen and (max-width: 992px) {
    .viewpaymentsContainer{
        padding: 20px 15px;
    }
    .payments-page.searchbar-div{
        flex-direction: column;
        align-items: flex-start;
    }
    .payments-page.searchbar{
        text-align: start;
        margin-bottom: 15px;
    }
    .payments-page.buttonCreate{
        align-self: end;
        flex: 1;
    }
}